import React from 'react';
import PropTypes from 'prop-types';
import { PivotData } from './Utilities';

// helper function for setting row/col-span in pivotTableRenderer
const spanSize = function (arr, i, j) {
  let x;
  if (i !== 0) {
    let asc, end;
    let noDraw = true;
    for (
      x = 0, end = j, asc = end >= 0;
      asc ? x <= end : x >= end;
      asc ? x++ : x--
    ) {
      if (arr[i - 1][x] !== arr[i][x]) {
        noDraw = false;
      }
    }
    if (noDraw) {
      return -1;
    }
  }
  let len = 0;
  while (i + len < arr.length) {
    let asc1, end1;
    let stop = false;
    for (
      x = 0, end1 = j, asc1 = end1 >= 0;
      asc1 ? x <= end1 : x >= end1;
      asc1 ? x++ : x--
    ) {
      if (arr[i][x] !== arr[i + len][x]) {
        stop = true;
      }
    }
    if (stop) {
      break;
    }
    len++;
  }
  return len;
};

function redColorScaleGenerator(values) {
  const min = Math.min.apply(Math, values);
  const max = Math.max.apply(Math, values);
  return x => {
    // eslint-disable-next-line no-magic-numbers
    const nonRed = 255 - Math.round((255 * (x - min)) / (max - min));
    return { backgroundColor: `rgb(255,${nonRed},${nonRed})` };
  };
}

function makeRenderer(opts = {}) {
  class TableRenderer extends React.PureComponent {
    render() {
      const pivotData = new PivotData(this.props);
      const colAttrs = pivotData.props.cols;
      const rowAttrs = pivotData.props.rows;
      const rowKeys = pivotData.getRowKeys();
      const colKeys = pivotData.getColKeys();
      // const grandTotalAggregator = pivotData.getAggregator([], []);
      const {
        data,
        id = 'pivot-table',
        className: tableClassName = '',
        colHeaders = [],
        rowHeaders = [],
        showSources = false,
        showNotes = false,
        onSourceClick = null,
        onNoteClick = null,
        forExport = false,
        tableTitle,
        tableFootnotes,
        tableCopyright,
      } = pivotData.props;

      let prevSourceIdx = null;
      let prevNotesIdx = null;
      const prevSources = {};
      const prevNotes = {};
      let valueCellColors = () => { };
      let rowTotalColors = () => { };
      let colTotalColors = () => { };
      if (opts.heatmapMode) {
        const colorScaleGenerator = this.props.tableColorScaleGenerator;
        const rowTotalValues = colKeys.map(x =>
          pivotData.getAggregator([], x).value()
        );
        rowTotalColors = colorScaleGenerator(rowTotalValues);
        const colTotalValues = rowKeys.map(x =>
          pivotData.getAggregator(x, []).value()
        );
        colTotalColors = colorScaleGenerator(colTotalValues);

        if (opts.heatmapMode === 'full') {
          const allValues = [];
          rowKeys.map(r =>
            colKeys.map(c =>
              allValues.push(pivotData.getAggregator(r, c).value())
            )
          );
          const colorScale = colorScaleGenerator(allValues);
          valueCellColors = (r, c, v) => colorScale(v);
        } else if (opts.heatmapMode === 'row') {
          const rowColorScales = {};
          rowKeys.map(r => {
            const rowValues = colKeys.map(x =>
              pivotData.getAggregator(r, x).value()
            );
            rowColorScales[r] = colorScaleGenerator(rowValues);
          });
          valueCellColors = (r, c, v) => rowColorScales[r](v);
        } else if (opts.heatmapMode === 'col') {
          const colColorScales = {};
          colKeys.map(c => {
            const colValues = rowKeys.map(x =>
              pivotData.getAggregator(x, c).value()
            );
            colColorScales[c] = colorScaleGenerator(colValues);
          });
          valueCellColors = (r, c, v) => colColorScales[c](v);
        }
      }

      const getClickHandler =
        this.props.tableOptions && this.props.tableOptions.clickCallback
          ? (value, rowValues, colValues) => {
            const filters = {};
            for (const i of Object.keys(colAttrs || {})) {
              const attr = colAttrs[i];
              if (colValues[i] !== null) {
                filters[attr] = colValues[i];
              }
            }
            for (const i of Object.keys(rowAttrs || {})) {
              const attr = rowAttrs[i];
              if (rowValues[i] !== null) {
                filters[attr] = rowValues[i];
              }
            }
            return e =>
              this.props.tableOptions.clickCallback(
                e,
                value,
                filters,
                pivotData
              );
          }
          : null;

      const Source = ({ id }) => (
        <span className="pvtSource" key={id} onClick={() => onSourceClick(id)}>
          [{id}]
        </span>
      );

      const Note = ({ id, idx }) => (
        <span className="pvtNote" key={idx} onClick={() => onNoteClick(id)}>
          /{id}
        </span>
      );

      const tableSource = (() => {
        if(data && data.length && data[0].source_id) {
          const source = data[0].source_id;
  
          if (data.every(el => el.source_id === source)) {
            return source;
          }
          return null;
        } 
        return null;
      })();

      const tableNotes = (() => {
        if(data && data.length && data[0].notes_ids) {
          const cellNotes = data[0].notes_ids || null;
          if (!cellNotes) { return null; }

          const notes = cellNotes
            .split(',')
            .filter(note =>
              data.every(el => el.notes_ids.split(',').includes(note))
            );

          if (notes.length) { return notes; }
          return null;
        }
        return null;
      })();

      const tableMetadata = [
        tableSource && showSources ? <Source id={tableSource} /> : null,
        tableNotes && showNotes
          ? tableNotes.map((note, i) => <Note id={note} idx={i} />)
          : null,
      ];

      return (
        <table id={id} className={`pvtTable ${tableClassName}`}>
          <thead>
            {forExport && tableTitle ? (
              <tr>
                <th colSpan="100%">{tableTitle}</th>
              </tr>
            ) : null}

            {colAttrs.map(function (c, j) {
              const colspan =
                forExport && (showSources || showNotes)
                  ? rowAttrs.length * 2 - 2
                  : rowAttrs.length;

              return (
                <tr key={`colAttr${j}`}>
                  {j === 0 && rowAttrs.length !== 0 && colspan > 0 && (
                    <th colSpan={colspan} rowSpan={colAttrs.length}>
                      {tableMetadata}
                    </th>
                  )}
                  <th className="pvtAxisLabel">
                    {colHeaders[j] ? colHeaders[j].toUpperCase() : c}
                  </th>

                  {/* source/notes column */}
                  {forExport && (showSources || showNotes) ? <th></th> : null}

                  {colKeys.map(function (colKey, i) {
                    let source = 0;
                    let notes = 0;
                    const currSourceKeys = [];
                    const currNotesKeys = [];
                    const txt = colKey[j];

                    let x = spanSize(colKeys, i, j);
                    if (x === -1) {
                      return null;
                    }
                    if (forExport && (showSources || showNotes)) {
                      x = x * 2 - 1;
                    }

                    // loop combinations starting from current one
                    for (let k = i; k < colKeys.length; k++) {
                      // exit if current text changes
                      if (colKeys[k][j] !== txt) {
                        break;
                      }

                      const colSource =
                        showSources && !tableSource
                          ? pivotData
                            .getAggregator(['source'], colKeys[k])
                            .value()
                          : null;

                      if (!colSource) {
                        source = null;
                      } else if (source === 0 || colSource === source) {
                        source = colSource;
                        currSourceKeys.push(colKeys[k]);
                      } else {
                        source = null;
                      }

                      const colNotes = showNotes
                        ? pivotData.getAggregator(['notes'], colKeys[k]).value()
                        : null;

                      if (!colNotes) {
                        notes = null;
                      } else if (
                        (notes === 0 || colNotes === notes) &&
                        JSON.stringify(colNotes.split(',')) !==
                        JSON.stringify(tableNotes)
                      ) {
                        notes = colNotes;
                        currNotesKeys.push(colKeys[k]);
                      } else {
                        notes = null;
                      }

                      if (!source && !notes) {
                        break;
                      }
                    }

                    // look for previously printed source
                    if (source && prevSources[source]) {
                      for (let n = 0; n < prevSources[source].length; n++) {
                        const prevColKey = prevSources[source][n];
                        // if found, clear source
                        if (prevColKey.every(el => colKey.includes(el))) {
                          source = null;
                          break;
                        }
                      }
                    }
                    // look for previously printed notes
                    if (notes && prevNotes[notes]) {
                      for (let n = 0; n < prevNotes[notes].length; n++) {
                        const prevColKey = prevNotes[notes][n];
                        // if found, clear notes
                        if (prevColKey.every(el => colKey.includes(el))) {
                          notes = null;
                          break;
                        }
                      }
                    }

                    if (source) {
                      if (prevSources[source]) {
                        prevSources[source].push(...currSourceKeys);
                      } else {
                        prevSources[source] = [...currSourceKeys];
                      }
                    }

                    if (notes) {
                      if (prevNotes[notes]) {
                        prevNotes[notes].push(...currNotesKeys);
                      } else {
                        prevNotes[notes] = [...currNotesKeys];
                      }
                    }

                    const metadata = [
                      source ? <Source id={source} /> : null,
                      notes
                        ? notes
                          .split(',')
                          .map((note, i) => <Note id={note} key={i} />)
                        : null,
                    ];

                    return (
                      <React.Fragment>
                        <th
                          className="pvtColLabel"
                          key={`colKey${i}`}
                          colSpan={x}
                          rowSpan={
                            j === colAttrs.length - 1 && rowAttrs.length !== 0
                              ? forExport && (showSources || showNotes)
                                ? 1
                                : 2
                              : 1
                          }
                          data-a-h="center"
                        >
                          {txt}
                          {!forExport ? metadata : null}
                        </th>

                        {forExport && (showSources || showNotes) ? (
                          <th>{metadata}</th>
                        ) : null}
                      </React.Fragment>
                    );
                  })}
                  {/* {j === 0 && (
                    <th
                      className="pvtTotalLabel"
                      rowSpan={
                        colAttrs.length + (rowAttrs.length === 0 ? 0 : 1)
                      }
                    >
                      Totals
                    </th>
                  )} */}
                </tr>
              );
            })}

            {rowAttrs.length !== 0 && (
              <tr>
                {rowAttrs.map(function (r, i) {
                  return (
                    <React.Fragment>
                      <th className="pvtAxisLabel" key={`rowAttr${i}`}>
                        {rowHeaders[i] ? rowHeaders[i].toUpperCase() : r}
                      </th>

                      {forExport && (showSources || showNotes) ? (
                        <th></th>
                      ) : null}
                    </React.Fragment>
                  );
                })}
                {/* <th className="pvtTotalLabel">
                  {colAttrs.length === 0 ? 'Totals' : null}
                </th> */}
              </tr>
            )}
          </thead>

          <tbody>
            {rowKeys.map(function (rowKey, i) {
              // const totalAggregator = pivotData.getAggregator(rowKey, []);

              // reset index variables when first key changes
              if (i > 0 && rowKey[0] !== rowKeys[i - 1][0]) {
                prevSourceIdx = null;
                prevNotesIdx = null;
              }

              return (
                <tr key={`rowKeyRow${i}`}>
                  {rowKey.map(function (txt, j) {
                    const x = spanSize(rowKeys, i, j);
                    if (x === -1) {
                      return forExport && (showSources || showNotes) ? (
                        <th></th>
                      ) : null;
                    }

                    let source = 0;
                    let notes = 0;

                    // loop combinations starting from current one
                    for (let k = i; k < rowKeys.length; k++) {
                      // exit if current text changes
                      if (rowKeys[k][j] !== txt) {
                        break;
                      }

                      // look for common source only if same dimension or no previous
                      if ([null, j].includes(prevSourceIdx)) {
                        const rowSource =
                          showSources && !tableSource
                            ? pivotData
                              .getAggregator(rowKeys[k], ['source'])
                              .value()
                            : null;

                        if (!rowSource) {
                          source = null;
                          prevSourceIdx = null;
                        } else if (source === 0) {
                          // save only the first one
                          source = rowSource;
                          prevSourceIdx = j;
                        } else if (source !== rowSource) {
                          source = null;
                          prevSourceIdx = null;
                        }
                      }

                      if ([null, j].includes(prevNotesIdx)) {
                        const rowNotes = showNotes
                          ? pivotData
                            .getAggregator(rowKeys[k], ['notes'])
                            .value()
                          : null;

                        if (!rowNotes) {
                          notes = null;
                          prevNotesIdx = null;
                        } else if (
                          notes === 0 &&
                          JSON.stringify(rowNotes.split(',')) !==
                          JSON.stringify(tableNotes)
                        ) {
                          // save only the first one
                          notes = rowNotes;
                          prevNotesIdx = j;
                        } else if (notes !== rowNotes) {
                          notes = null;
                          prevNotesIdx = null;
                        }
                      }

                      if (!source && !notes) {
                        break;
                      }
                    }

                    const metadata = [
                      source ? <Source id={source} /> : null,
                      notes
                        ? notes
                          .split(',')
                          .map((note, i) => <Note id={note} key={i} />)
                        : null,
                    ];

                    return (
                      <React.Fragment>
                        {/* CATEGORIAS TITULOS FILAS */}
                        <th
                          key={`rowKeyLabel${i}-${j}`}
                          className="pvtRowLabel"
                          rowSpan={x}
                          colSpan={
                            j === rowAttrs.length - 1 && colAttrs.length !== 0
                              ? forExport && (showSources || showNotes)
                                ? 1
                                : 2
                              : 1
                          }
                          data-a-v="middle"
                        >
                          {txt}

                          {!forExport ? metadata : null}
                        </th>

                        {forExport && (showSources || showNotes) ? (
                          <th>{metadata}</th>
                        ) : null}
                      </React.Fragment>
                    );
                  })}

                  {colKeys.map(function (colKey, j) {
                    const aggregator = pivotData.getAggregator(rowKey, colKey);
                    const cell = aggregator.value();

                    const source = (() => {
                      if (
                        pivotData.props.showSources &&
                        cell &&
                        cell.source_id
                      ) {
                        const rowSource = pivotData
                          .getAggregator(rowKey, ['source'])
                          .value();
                        const colSource = pivotData
                          .getAggregator(['source'], colKey)
                          .value();

                        if (!rowSource && !colSource) {
                          return cell.source_id;
                        }
                        return null;
                      }
                      return null;
                    })();

                    const notes = (() => {
                      if (pivotData.props.showNotes && cell && cell.notes_ids) {
                        const rowNotes = pivotData
                          .getAggregator(rowKey, ['notes'])
                          .value();
                        const colNotes = pivotData
                          .getAggregator(['notes'], colKey)
                          .value();

                        if (!rowNotes && !colNotes) {
                          return cell.notes_ids;
                        }
                        return null;
                      }
                      return null;
                    })();

                    const metadata = [
                      source ? <Source id={source} /> : null,
                      notes
                        ? notes
                          .split(',')
                          .map((note, i) => <Note id={note} key={i} />)
                        : null,
                    ];

                    return (
                      <React.Fragment>
                        {/* DATO DE LA CELDA */}
                        <td
                          className="pvtVal"
                          key={`pvtVal${i}-${j}`}
                          data-t={cell ? 'n' : 's'}
                          onClick={
                            getClickHandler &&
                            getClickHandler(aggregator.value(), rowKey, colKey)
                          }
                          style={valueCellColors(
                            rowKey,
                            colKey,
                            aggregator.value()
                          )}
                        >
                          {/* {aggregator.format(aggregator.value()} */}
                          {cell
                            ? [
                              !forExport ? metadata : null,
                              aggregator.format(cell.value),
                            ]
                            : null}
                        </td>

                        {forExport && (showSources || showNotes) ? (
                          <td>{metadata}</td>
                        ) : null}
                      </React.Fragment>
                    );
                  })}
                  {/* <td
                      className="pvtTotal"
                      onClick={
                        getClickHandler &&
                        getClickHandler(totalAggregator.value(), rowKey, [null])
                      }
                      style={colTotalColors(totalAggregator.value())}
                    >
                      {totalAggregator.format(totalAggregator.value())}
                    </td> */}
                  {/* </td> */}
                </tr>
              );
            })}

            {forExport && tableFootnotes ? (
              <React.Fragment>
                {tableFootnotes}
              </React.Fragment>
            ) : null}

            {forExport && tableCopyright ? (
              <React.Fragment>
                <tr>
                  <td colSpan="100%"></td>
                </tr>
                <tr>
                  <td colSpan="100%">{tableCopyright}</td>
                </tr>
              </React.Fragment>
            ) : null}
            <tr>
              {/* <tr>
              <th
                className="pvtTotalLabel"
                colSpan={rowAttrs.length + (colAttrs.length === 0 ? 0 : 1)}
              >
                Totals
              </th>

              {colKeys.map(function(colKey, i) {
                const totalAggregator = pivotData.getAggregator([], colKey);
                return (
                  <td
                    className="pvtTotal"
                    key={`total${i}`}
                    onClick={
                      getClickHandler &&
                      getClickHandler(totalAggregator.value(), [null], colKey)
                    }
                    style={rowTotalColors(totalAggregator.value())}
                  >
                    {totalAggregator.format(totalAggregator.value())}
                  </td>
                );
              })}

              <td
                onClick={
                  getClickHandler &&
                  getClickHandler(grandTotalAggregator.value(), [null], [null])
                }
                className="pvtGrandTotal"
              >
                {grandTotalAggregator.format(grandTotalAggregator.value())}
              </td> */}
            </tr>
          </tbody>
        </table>
      );
    }
  }

  TableRenderer.defaultProps = PivotData.defaultProps;
  TableRenderer.propTypes = PivotData.propTypes;
  TableRenderer.defaultProps.tableColorScaleGenerator = redColorScaleGenerator;
  TableRenderer.defaultProps.tableOptions = {};
  TableRenderer.propTypes.tableColorScaleGenerator = PropTypes.func;
  TableRenderer.propTypes.tableOptions = PropTypes.object;
  return TableRenderer;
}

class TSVExportRenderer extends React.PureComponent {
  render() {
    const pivotData = new PivotData(this.props);
    const rowKeys = pivotData.getRowKeys();
    const colKeys = pivotData.getColKeys();
    if (rowKeys.length === 0) {
      rowKeys.push([]);
    }
    if (colKeys.length === 0) {
      colKeys.push([]);
    }

    const headerRow = pivotData.props.rows.map(r => r);
    if (colKeys.length === 1 && colKeys[0].length === 0) {
      headerRow.push(this.props.aggregatorName);
    } else {
      colKeys.map(c => headerRow.push(c.join('-')));
    }

    const result = rowKeys.map(r => {
      const row = r.map(x => x);
      colKeys.map(c => {
        const v = pivotData.getAggregator(r, c).value();
        row.push(v ? v : '');
      });
      return row;
    });

    result.unshift(headerRow);

    return (
      <textarea
        value={result.map(r => r.join('\t')).join('\n')}
        style={{ width: window.innerWidth / 2, height: window.innerHeight / 2 }}
        readOnly={true}
      />
    );
  }
}

TSVExportRenderer.defaultProps = PivotData.defaultProps;
TSVExportRenderer.propTypes = PivotData.propTypes;

export default {
  Table: makeRenderer(),
  'Table Heatmap': makeRenderer({ heatmapMode: 'full' }),
  'Table Col Heatmap': makeRenderer({ heatmapMode: 'col' }),
  'Table Row Heatmap': makeRenderer({ heatmapMode: 'row' }),
  'Exportable TSV': TSVExportRenderer,
};
